import {Box, Container, Grid, Typography} from "@mui/material";
import ArticleCard from "../components/ArticleCard/ArticleCard";
import Head from "next/head";

export default function Home() {
    return (
        <div>
            <Head>
                <title>My Duque - Articles</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width"/>
                <meta name="robots"/>
                <meta name="description"
                      content="Articles from a Computer Science perspective, AWS, Java, concentration, focus, monetization"/>
            </Head>
            <Box>
                <Box sx={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${"/images/backgroundImage.jpg"})`,
                    height: "500px",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#fff",
                    fontSize: "4rem",
                }}>
                </Box>
            </Box>
            <Container maxWidth="lg" sx={{
                paddingTop: "30px"
            }}>
                <Typography variant="h2" sx={{
                    paddingTop: "3",
                    fontWeight: 800,
                    paddingBottom: "30px"
                }}>
                    Articles
                </Typography>
                <Grid container spacing={3}>
                    <ArticleCard title="How to filter a MongoDB noSQL database with Spring Data and Criteria API"
                                 description="In this tutorial we demonstrate how to create a spring boot rest endpoint which queries and filters data from a MongoDB noSQL database using spring-boot-starter-data-mongodb and Criteria API."
                                 image="/images/spring-boot-rest-mongodb-nosql-criteria-filters/spring-mongodb-criteria.png"
                                 author="Miguel Duque"
                                 authorImage="/images/miguel-small.jpg"
                                 lastUpdatedDate="Dec 03, 2022"
                                 href="/java/spring-boot-rest-mongodb-nosql-criteria-filters"/>
                    <ArticleCard title="How to Paginate and Sort a Spring Boot Rest API with MongoDB noSQL database"
                                 description="In this tutorial we demonstrate how to sort and paginate a spring boot rest endpoint which queries data from a MongoDB noSQL database using the spring-boot-starter-data-mongodb."
                                 image="/images/spring-boot-rest-pagination-mongodb-nosql/spring-rest-pagination.png"
                                 author="Miguel Duque"
                                 authorImage="/images/miguel-small.jpg"
                                 lastUpdatedDate="Dec 02, 2022"
                                 href="/java/spring-boot-rest-pagination-mongodb-nosql"/>
                    <ArticleCard title="How to Sort a Spring Boot Rest API with MongoDB noSQL database"
                                 description="In this tutorial we demonstrate how to sort a spring boot rest endpoint which queries data from a MongoDB noSQL database using the spring-boot-starter-data-mongodb"
                                 image="/images/spring-boot-rest-sort-mongodb-nosql/spring-rest-sort.png"
                                 author="Miguel Duque"
                                 authorImage="/images/miguel-small.jpg"
                                 lastUpdatedDate="Dec 01, 2022"
                                 href="/java/spring-boot-rest-sort-mongodb-nosql"/>
                    <ArticleCard title="How to use Spring Data JPA Specifications to filter SQL queries with Join Tables"
                                 description="Spring Data JPA Specification allows us to develop a clean and reusable way of creating queries without much SQL knowledge!"
                                 image="/images/spring-boot-rest-jpa-specification/spring-jpa-specification.png"
                                 author="Miguel Duque"
                                 authorImage="/images/miguel-small.jpg"
                                 lastUpdatedDate="Nov 28, 2022"
                                 href="/java/spring-boot-rest-jpa-specification"/>
                    <ArticleCard title="How to Sort a Spring Boot Rest API from SQL database"
                                 description="Server data sorting is a frequent requirement on any data driven application. In this tutorial we demonstrate how to sort a spring boot rest endpoint which queries data from a SQL database using Spring JPA."
                                 image="/images/spring-boot-rest-sort-jpa-sql/spring-rest-sort.png"
                                 author="Miguel Duque"
                                 authorImage="/images/miguel-small.jpg"
                                 lastUpdatedDate="Nov 23, 2022"
                                 href="/java/spring-boot-rest-sort-jpa-sql"/>
                    <ArticleCard title="How to Paginate a Spring Boot Rest API from SQL database"
                                 description="Server data pagination is essential on any application that handles large datasets. In this tutorial we demonstrate how to sort and paginate a spring boot rest endpoint which queries data from a SQL database using Spring JPA."
                                 image="/images/spring-boot-rest-pagination-jpa-sql/spring-rest-pagination.png"
                                 author="Miguel Duque"
                                 authorImage="/images/miguel-small.jpg"
                                 lastUpdatedDate="Nov 20, 2022"
                                 href="/java/spring-boot-rest-pagination-jpa-sql"/>
                    <ArticleCard title="Surrounded by idiots summary"
                                 description="A great and easy to ready book to help you understand human behavior and how to effectively communicate in business and in life. This post gives you a sneak peek of Surrounded by idiots with a summary of what you will find. I hope that my short resume is enough to convince you to read it!"
                                 image="/images/surrounded-by-idiots/surrounded-by-idiots-logo.jpg"
                                 author="Sandra Figueirinha"
                                 authorImage="/images/sandra-small.jpg"
                                 lastUpdatedDate="Nov 13, 2022"
                                 href="/books/surrounded-by-idiots"/>
                    <ArticleCard title="How to pass AWS Cloud Practitioner Certification Exam"
                                 description="What is AWS Cloud Practitioner Certification, why you should do it, and what was my strategy to accomplish it."
                                 image="/images/ccp/aws-ccp.png"
                                 author="Miguel Duque"
                                 authorImage="/images/miguel-small.jpg"
                                 lastUpdatedDate="Nov 02, 2022"
                                 href="/aws/ccp"/>
                    <ArticleCard title="Why you should switch to Rumble from Youtube"
                                 description="Rumble is a new video hosting platform with great benefits when compared to YouTube. Find out here why you should switch to this new platform, both from a creator or consumer point of view."
                                 image="/images/rumble/rumble.png"
                                 author="Miguel Duque"
                                 authorImage="/images/miguel-small.jpg"
                                 lastUpdatedDate="Nov 01, 2022"
                                 href="/monetization/rumble"/>
                    <ArticleCard title="4 benefits of rain sound"
                                 description="Rain noise, also known as pink noise, is a relaxing sound which provides multiple benefits, from stress relief to concentration."
                                 image="/images/rain-small.jpg"
                                 author="Miguel Duque"
                                 authorImage="/images/miguel-small.jpg"
                                 lastUpdatedDate="Oct 31, 2022"
                                 href="/relax/rain-sound"/>
                    <ArticleCard title="Setup and share Buy Me A Coffee in less than 5 minutes"
                                 description="BuyMeACoffee is a platform to easily allow your followers to support you. Follow this tutorial to setup and share it in less than 5 minutes."
                                 image="/images/buy-me-a-coffee/bmc-logo.jpg"
                                 author="Miguel Duque"
                                 authorImage="/images/miguel-small.jpg"
                                 lastUpdatedDate="Oct 09, 2022"
                                 href="/monetization/buy-me-a-coffee"/>
                    <ArticleCard title="Turn on/off AWS Fargate Tutorial Guide"
                                 description="How to implement some cost saving mechanisms on AWS Fargate by automatically turning off your Aws Fargate cluster."
                                 image="/images/aws-fargate-turn-on-off-pipelines/fargate.jpg"
                                 author="Miguel Duque"
                                 authorImage="/images/miguel-small.jpg"
                                 lastUpdatedDate="Oct 01, 2022"
                                 href="/aws/aws-fargate-turn-on-off-pipelines"/>
                    <ArticleCard title="Mapstruct Tutorial Guide with Spring"
                                 description="How to take advantage of Mapstruct to greatly reduce the amount of boilerplate code that would regularly be written by hand."
                                 image="/images/mapstruct/mapstruct.jpg"
                                 author="Miguel Duque"
                                 authorImage="/images/miguel-small.jpg"
                                 lastUpdatedDate="Sep 27, 2022"
                                 href="/java/mapstruct"/>
                </Grid>
            </Container>
        </div>
    )
}
