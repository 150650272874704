import {Avatar, Box, Card, CardActions, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Link from "next/link";
import Image from "next/image";
import toast from "react-hot-toast";

export default function ArticleCard(props) {
    let copied = "";
    return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card sx={{maxWidth: "100%", height: "520px"}} style={{position: "relative"}}>
                <Link href={props.href}>
                    <CardMedia
                        component="img"
                        height="240"
                        image={props.image}
                        alt="Article Image"
                    />
                </Link>
                <Link href={props.href}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {props.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {props.description}
                        </Typography>
                    </CardContent>
                </Link>
                <CardActions sx={{display: "flex", margin: "0 10px", justifyContent: "space-between"}} style={{bottom: "0", position: "absolute", width: "90%"}}>
                    <Box sx={{display: "flex"}}>
                        <Avatar>
                            <Image src={props.authorImage} layout="fill" alt="Author"/>
                        </Avatar>
                        <Box ml={2}>
                            <Typography gutterBottom variant="subtitle2" component="p">
                                {props.author}
                            </Typography>
                            <Typography gutterBottom variant="subtitle2" color="textSecondary" component="p">
                                {props.lastUpdatedDate}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <ContentCopyIcon onClick={() => {
                            navigator.clipboard.writeText(window.location.href.replace(/\/*$/, '') + props.href);
                            toast.success('Successfully copied!');
                        }}/>
                        <br/>
                        {copied}
                    </Box>
                </CardActions>
            </Card>
        </Grid>
    );
}